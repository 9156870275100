import * as httpClient from '../httpClient';
import { getCurrentLocale } from '../language';
import { cookiesLib } from '../cookies';

export const prepareLeadData = (listing, formFields, leadSource, leadType) => {
  const { id } = listing;
  const { id: partyId } = listing.owner;
  const leadData = {
    id: partyId,
    source: leadSource,
    formFields: formFields,
    type: leadType,
    imtId: id
  };
  leadData.gaClientID = cookiesLib.get('_ga');
  return leadData;
};

export const sendLead = (leadData) => {
  let lead = buildLead(leadData);
  lead.locale = getCurrentLocale(true);
  return httpClient.getHttpClient().post('/lead', lead);
};

const buildLead = (leadData) => {
  let fullName = leadData.formFields.name.split(' ');
  const lead = {
    id: leadData.id,
    leadSource: leadData.source,
    gaClientID: leadData.gaClientID,
    type: leadData.type,
    customer: {
      firstName: fullName[0],
      lastName: fullName[1] || '',
      email: leadData.formFields.email,
      comments: leadData.formFields.comments,
      phone: {
        type: 'Phone',
        number: leadData.formFields.phone
      },
      address: {
        ...(leadData.formFields.zip && { zip: leadData.formFields.zip }),
        ...(leadData.formFields.country && {
          country: leadData.formFields.country
        })
      }
    }
  };

  if (leadData.copyOem) {
    lead.copyOem = leadData.copyOem;
  }

  if (leadData.oemId) {
    lead.oemId = leadData.oemId;
  }
  if (leadData.brandId) {
    lead.brandId = leadData.brandId;
  }

  if (leadData.imtId) {
    lead.imtId = leadData.imtId;
  }

  return lead;
};
