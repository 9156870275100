export const getItem = (item) => {
  return localStorage.getItem(item);
};

export const setItem = (itemName, data) => {
  return localStorage.setItem(itemName, data);
};

export const removeItem = (item) => {
  return localStorage.removeItem(item);
};

export const getSessionItem = (item) => {
  return sessionStorage.getItem(item);
};

export const setSessionItem = (itemName, data) => {
  return sessionStorage.setItem(itemName, data);
};

export const removeSessionItem = (item) => {
  return sessionStorage.removeItem(item);
};
