import PropTypes from 'prop-types';

export const contactPropTypes = PropTypes.shape({
  address: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    street2: PropTypes.string,
    street: PropTypes.string,
    subdivision: PropTypes.string,
  }),
  email: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
});

export const listingPropTypes = PropTypes.shape({
  id: PropTypes.number,
  contact: contactPropTypes,
  featureType: PropTypes.shape({
    enhanced: PropTypes.bool,
  }),
  location: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      subdivision: PropTypes.string,
    }),
  }),
  make: PropTypes.string,
  media: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.shape({
      modified: PropTypes.string,
    }),
    mediaType: PropTypes.string,
    url: PropTypes.string,
    width: PropTypes.number,
  })),
  model: PropTypes.string,
  owner: PropTypes.shape({
    name: PropTypes.string,
    rootName: PropTypes.string,
  }),
  portalLink: PropTypes.string,
  price: PropTypes.shape({
    hidden: PropTypes.bool,
    type: PropTypes.shape({
      amount: PropTypes.objectOf(PropTypes.number)
    }),
  }),
  year: PropTypes.number,
  similarBoats: PropTypes.shape({
    records: PropTypes.shape({
      count: PropTypes.number,
      records: PropTypes.array
    }),
    showAllParams: PropTypes.object
  })
});

export const premiumFeaturedAdPropTypes = PropTypes.shape({
  id: PropTypes.number,
  imtId: PropTypes.number,
  photoUrl: PropTypes.string,
  logoUrl: PropTypes.string,
  provisioningId: PropTypes.number,
  provisioningName: PropTypes.string,
  location: PropTypes.string,
  listingUrl: PropTypes.string,
  externalUrl: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  status: PropTypes.string,
  title: PropTypes.string,
  portalId: PropTypes.number,
  container: PropTypes.string,
  description: PropTypes.string,
  hideInfobar: PropTypes.bool,
});

export const premiumPlacementPropTypes = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  mediaUrl: PropTypes.string,
  sellerName: PropTypes.string,
  sellerLogo: PropTypes.string,
  boatLink: PropTypes.string,
  showMore: PropTypes.string,
  contact: PropTypes.string,
});

export const privateFeaturedAdTypes =  PropTypes.arrayOf(PropTypes.shape({
  id: PropTypes.number,
  year: PropTypes.number,
  make: PropTypes.string,
  model: PropTypes.string,
  class: PropTypes.string,
  location: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      subdivision: PropTypes.string
    })
  }),
  price: PropTypes.shape({
    hidden: PropTypes.bool,
    type: PropTypes.shape({
      amount: PropTypes.objectOf(PropTypes.number)
    })
  }),
  media: PropTypes.array,
  portalLink: PropTypes.string
}));
